<template>
  <div>
    <v-card color="grey lighten-1" class="mb-12" elevation="0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="4" class="my-2">
            <v-autocomplete
              v-model="trip.route"
              :items="routes"
              outlined
              :readonly="mode==3"
              item-text="name"
              item-value="id"
              :label="$t('route')"
              persistent-hint
              return-object
              required
              :rules="requiredRules"
              :hint="
                selectedRoute != null
                  ? (selectedRoute.is_morning
                    ? $t('morning_route')
                    : $t('afternoon_route'))
                  : trip.route != null ? (trip.route.is_morning ? $t('morning_route') : $t('afternoon_route')) : ''
              "
              @change="routeSelected"
            >
              <template v-slot:item="{ on, attrs, item }">
                <v-list-item
                    v-on="on"
                  v-bind="attrs"
                    v-if="item"
                    :key="item.id"
                    >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.name }}
                            <v-chip dense
                            :color="getTimeColor(item.is_morning)" dark>
                                {{ item.is_morning ? $t('morning') : $t('afternoon') }}
                            </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>

                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="4" class="my-2">
            <v-dialog
              ref="effective_date"
              :return-value.sync="trip.effective_date"
              v-model="modal"
              :disabled="mode==3"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="trip.effective_date"
                  :label="$t('effective_date')"
                  dense
                  outlined
                  readonly
                  required
                  :rules="requiredRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="trip.effective_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  {{$t('cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.effective_date.save(trip.effective_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="4" class="my-2">
            <v-text-field
              v-model="trip.repetition_period"
              type="number"
              outlined
              dense
              :readonly="mode==3"
              :label="$t('repeated_every_days')"
              placeholder="Enter repetition period (days)"
              :hint="$t('no_repetition')"
              persistent-hint
              required
              :rules="repeatRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="my-2">
            <v-dialog
              ref="first_stop_time"
              :return-value.sync="trip.first_stop_time"
              v-model="modal2"
              :disabled="mode==3"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="trip.first_stop_time"
                  outlined
                  dense
                  :label="$t('arrival_time_first_stop')"
                  readonly
                  required
                  :rules="requiredRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="modal2"
                v-model="trip.first_stop_time"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  {{$t('cancel')}}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.first_stop_time.save(trip.first_stop_time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="4" class="my-2">
            <v-tooltip v-if="mode==1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="trip.stop_to_stop_avg_time"
                  type="number"
                  outlined
                  dense
                  :label="$t('stop_to_stop_time')"
                  :placeholder="$t('stop_to_stop_average')"
                  :hint="$t('change_later')"
                  persistent-hint
                  required
                  :rules="timeRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <span>{{$t('trip_rule_text')}}</span>
            </v-tooltip>
            <v-text-field v-else
              v-model="trip.stop_to_stop_avg_time"
              type="number"
              outlined
              dense
              :readonly="mode==3"
              :label="$t('stop_to_stop_time')"
              :placeholder="$t('Enter average stop-to-stop time (min)')"
              :hint="$t('change_later')"
              persistent-hint
              required
              :rules="timeRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-btn v-if="mode!=3" color="primary" @click="next"> {{$t('continue')}} </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    trip: Object,
    routes: Array,
    mode: Number,
  },
  data() {
    return {
      modal: false,
      modal2: false,
      valid: true,
      requiredRules: [(v) => !!v || "Required."],
      repeatRules: [(v) => /^(0|[1-9]\d*)$/.test(v) || "Must be 0 or greater"],
      timeRules: [(v) => /^[1-9][0-9]*$/.test(v) || "Must be greater than 0"],
      selectedRoute: null,
    };
  },
  beforeMount() {
    this.trip.effective_date = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
  },
  methods: {
    next(event) {
      if (this.validate()) this.$emit("next", this.trip);
      else this.$emit("invalid");
    },
    validate() {
      this.valid = false;
      let v = this.$refs.form.validate();
      if (v) {
        this.valid = true;
        return true;
      }
      return false;
    },
    routeSelected(r) {
      this.selectedRoute = r;
    },
    getTimeColor(is_morning) {
      return is_morning ? "success" : "warning";
    },
  },
};
</script>
